import React, { useState } from "react";
import { NotesModal } from "./NotesModal";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";

export const MemberWithoutCalling = ({ member, updateIsPrioritized, updateIneligibleForCalling, zoomedOut, sessionInfo }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [memberNotes, setMemberNotes] = useState([]);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isPrioritized, setIsPrioritized] = useState(member.is_prioritized);

  const handleNameClick = async () => {
    const notes = await fetchMemberNotes();
    setMemberNotes(notes);
    setIsNotesModalOpen(true);
  };

  const handleAddNote = async (note) => {
    const newNote = await addNoteToDatabase(member.id, note);
    setMemberNotes([newNote, ...memberNotes]);
  };

  const fetchMemberNotes = async () => {
    try {
      // make this a GET req instead
      const res = await fetch("/api/list_notes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          memberId: member.id,
        }),
      });
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error fetching member notes", error);
      return [];
    }
  };

  const addNoteToDatabase = async (note) => {
    try {
      const res = await fetch("/api/create_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          memberId: member.id,
          note: note,
        }),
      });
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error adding note", error);
      return null;
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      const res = await fetch("/api/delete_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ noteId: noteId })
      });
      const data = await res.json();
      if (data) {
        setMemberNotes(memberNotes.filter(note => note.id !== noteId));
      }
    } catch (error) {
      console.error("Error deleting note", error);
    }
  };

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  let isDefaultPicture = false;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  } else {
    currentPhotoSrc = "/no_profile_picture.jpeg";
    isDefaultPicture = true;
  }

  const name = member.member_name.split(",").map((part) => part.trim()).reverse().join(" ");

  const monthsSinceMovedIn = member.months_since_moved_in; // member.months_since_sustained;
  // Determine border color based on months since sustained
  let borderColor = "#b0b0b0"; // Default border color
  if (member.is_recommended) {
    borderColor = "#f6d76e"; // custom, but close to cornflower blue
  } else if (isPrioritized) {
    borderColor = "#87DECC"
  } else if (monthsSinceMovedIn >= 6) {
    borderColor = "#4285F4"; // custom, but close to cornflower blue
  } else if (monthsSinceMovedIn >= 3) {
    borderColor = "#FFBBFF"; // light magenta 1
  }

  const handleUpdateIsPrioritized = () => {
    setIsPrioritized(!isPrioritized)
    updateIsPrioritized(member, !isPrioritized);
  };

  if (zoomedOut) {
    return (
      <div
        className="member-card"
        style={{
          border: `5px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "125px",
            width: "125px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <img
            src={currentPhotoSrc}
            alt={name}
            className="member-photo"
            style={isDefaultPicture ? { height: "75px", width: "75px" } : {}}
          />
        </div>
        <div
          style={{
            fontSize: "15px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "140px",
            marginTop: "17.5px",
            /*cursor: "pointer"*/
          }}
          /*onClick={() => handleNameClick()}*/
        >
          <strong>{name}</strong>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `5px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-5px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded" style={{ fontSize: "24px" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              {!isPrioritized && (<DropdownMenuRadioItem
                className='dropdown-item'
                onClick={handleUpdateIsPrioritized}
                style={{ fontSize: "18px" }}
              >
                Prioritize for calling
              </DropdownMenuRadioItem>)}
              {isPrioritized && (<DropdownMenuRadioItem
                className='dropdown-item'
                onClick={handleUpdateIsPrioritized}
                style={{ fontSize: "18px" }}
              >
                Clear priority status
              </DropdownMenuRadioItem>)}
              <DropdownMenuRadioItem
                className='dropdown-item'
                onClick={() => updateIneligibleForCalling(member, true)}
                style={{ fontSize: "18px" }}
              >
                Ineligible for calling
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div
          style={{
            position: "relative",
            width: "240px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                right: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <img
              src={currentPhotoSrc}
              alt={name}
              className="member-photo"
              style={isDefaultPicture ? { height: "100px", width: "100px" } : {}}
            />
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                left: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            fontSize: "18px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            marginTop: "36px",
            /*cursor: "pointer",*/
          }}
          /*onClick={() => handleNameClick()}*/
        >
          <strong>{name}</strong>
        </div>
      </div>
      <NotesModal
        isOpen={isNotesModalOpen}
        onClose={() => setIsNotesModalOpen(false)}
        memberId={member.id}
        memberName={member.member_name}
        notes={memberNotes}
        onAddNote={handleAddNote}
        onDeleteNote={handleDeleteNote}
        sessionInfo={sessionInfo}
      />
    </>
  );
};