import React, { useState } from "react";
import { formatName } from "./utils";

export const NotesModal = ({ isOpen, onClose, memberId, memberName, notes, onAddNote, onDeleteNote, sessionInfo }) => {
  const [newNote, setNewNote] = useState('');
  const [noteToDelete, setNoteToDelete] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddNote(memberId, newNote);
    setNewNote('');
  };

  const handleDeleteClick = (e, note) => {
    e.stopPropagation();
    setNoteToDelete(note);
  };

  const confirmDelete = (e) => {
    e.stopPropagation();
    if (noteToDelete) {
      onDeleteNote(noteToDelete.id);
      setNoteToDelete(null);
    }
  };

  const cancelDelete = (e) => {
    e.stopPropagation();
    setNoteToDelete(null);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="notes-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="notes-modal-header">
          <h2>Notes for {formatName(memberName)}</h2>
          <button className="close-button" onClick={onClose}>
            <i className="bx bx-x"></i>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <textarea
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            placeholder="Write a note..."
            rows="4"
            style={{ width: '100%' }}
          />
          <div className="notes-modal-button">
            <button type="submit" disabled={!newNote.trim()}>Save</button>
          </div>
        </form>
        {notes.length > 0 && (
          <>
            <hr style={{ margin: '20px 0', border: '0', borderTop: '1px solid #ccc' }} />
            <div className="notes-list" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
              {notes.map((note, index) => (
                <div key={index} className="note-item" style={{ position: 'relative', padding: '10px', display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    <p>{note.note}</p>
                    <small>
                      {note.notetaker_title} on {new Date(note.created_date).toLocaleString()}
                    </small>
                  </div>
                  {note.notetaker_id === sessionInfo.memberId && (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                      <i 
                        className="bx bx-trash delete-icon" 
                        onClick={(e) => handleDeleteClick(e, note)}
                      ></i>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {noteToDelete && (
        <div className="confirm-delete-modal">
          <p>Are you sure you want to delete this note?</p>
          <button onClick={(e) => cancelDelete(e)}>No</button>
          <button onClick={(e) => confirmDelete(e)}>Yes</button>
        </div>
      )}
    </div>
  );
};