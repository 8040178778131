export const ViewToggleIcon = ({ toggleView, zoomedOut }) => (
  <div
    onClick={toggleView}
    style={{
      cursor: "pointer",
      fontSize: "24px",
      marginRight: "15px",
    }}
  >
    <i className={`bx ${zoomedOut ? "bx-zoom-in" : "bx-zoom-out"}`}></i>
  </div>
);