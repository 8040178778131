import React, { useEffect, useState } from "react";
import "./App.css";
import { jwtDecode } from "jwt-decode";
import Select from "react-select";
import { MemberWithCalling } from "./MemberWithCalling";
import { AddCallingCard } from "./AddCallingCard";
import { PlaceholderCard } from "./PlaceholderCard";
import { RecommendedCard } from "./RecommendedCard";
import { ApprovedCard } from "./ApprovedCard";
import { CalledCard } from "./CalledCard";
import { MembersWithCallingsLegend } from "./MembersWithCallingsLegend";
import { NeedsCallingLegend } from "./NeedsCallingLegend";
import { MemberWithoutCalling } from "./MemberWithoutCalling";
import { IneligibleForCalling } from "./IneligibleForCalling";
import { IneligibleForCallingLegend } from "./IneligibleForCallingLegend";
import { Header } from "./Header";
import { MinisteringLegend } from "./MinisteringLegend";
import { Ministering } from "./Ministering";

// TODO BEFORE 9/15:
// make so that the needsCalling doesn't cause a flicker on the website; separate loading state for Needs a calling!!!
// update is_pending_set_apart NOT is_set_apart in CALLINGS!!!
// need to be able to select a release date after marking someone as "pending release"
// who inputs releases into LCR? whoever performed the release? like does that go through the google sheet first or no? is that brady or no?
// expire the session (like after 2 hours or something)
// EQ/exec sec approved by Stake
// EQ approved by... who??? Recommended by... who??
// RS approved by... who??? Recommended by... who??
// Add a boolean requires_stake_approval that will go on organizations
// There should be a multiselect dropdown after clicking someone to recommend where they select who will approve the calling!!!
// If a MemberWithCalling is_set_apart = true, then you should not be able to change it to false!!!
// Fetch the no profile picture first
// Some sort of CSV download or something or just table version or list version of who needs to be sustained, who is awaiting approvals, who needs to be called, etc. That's basically what ldscallings.com does.
// rename to "members with callings" and "members without callings"

// upcoming
//// add customizable email notifications
//// compatibility with mobile device (please don't check so that I can retain my self-respect)
//// prospective elder? i don't know what that means but saw some stats on it in the google sheet

// list of constraints/considerations when auto-generating ministering assignments
//// what is their current calling? (how busy are they already) (look at their existing callings in the callings table)
//// what is the spouse's calling (family ward specific) (look at their spouses existing callings in the callings table)
//// what is their activity level? (don't pair up two inactive people) (need to track whether or not they are active + allow the bishopric to override whatever field comes back from the api)
//// how old is the person? (weighted varying amount based on ward demographic) (track their age )
//// based on survey - your needs + fav way to minister
//// every sister needs to have a melchezidek priesthood holder ministering to her
//// ysa-specific - household (yes to roommates ministering together or no to roommates ministering together); and if roommates should be ministered to
//// cannot minister to someone who ministers to them. arrows cannot be bidirectional
//// ministering assignments changing approx monthly, maybe every 2 months (get the assignments to be over sooner because you don't know it's a geniune friendship until after the assignment)

// 
//// easier way to schedule interviews

export const App = () => {
  const [loading, setLoading] = useState(false);
  const [overallNumbers, setOverallNumbers] = useState(null);
  const [sessionInfo, setSessionInfo] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [membersWithCallings, setMembersWithCallings] = useState([]);
  const [needsCalling, setNeedsCalling] = useState([]);
  const [ineligibleForCalling, setIneligibleForCalling] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [issuersList, setIssuersList] = useState([]);
  const [activeTab, setActiveTab] = useState("membersWithCallings");
  const [zoomedOut, setZoomedOut] = useState(true);
  const [placeholders, setPlaceholders] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [approved, setApproved] = useState([]);
  const [called, setCalled] = useState([]);
  const [unitDetails, setUnitDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const toggleView = () => {
    setZoomedOut(!zoomedOut);
  };

  const handleTabSelection = (newTab) => {
    setActiveTab(newTab)
    setZoomedOut(true)
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id:
          "181042165476-h5pg44f80mtbf03qrb437olnjjk0rr0m.apps.googleusercontent.com",
        callback: handleCredentialResponse,
        auto_prompt: false,
      });
      window.google.accounts.id.prompt();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  async function handleCredentialResponse(response) {
    const decoded = jwtDecode(response.credential);

    const res = await fetch("/api/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: decoded.email }),
    });
    if (res.ok) {
      initializePage();
    } else {
      setErrorMessage("Please email your name and ward to elizahales@gmail.com for access.")
    }
  }

  async function getSessionInfo() {
    const response = await fetch("/api/session-info");
    const sessionInfo = await response.json();
    return sessionInfo;
  }
  // superficially deleting note not working

  async function fetchImage(url) {
    try {
      const photoRes = await fetch(`/api/image?url=${encodeURIComponent(url)}`);
      if (photoRes.ok) {
        const blob = await photoRes.blob();
        return URL.createObjectURL(blob);
      }
    } catch (error) {
      console.error(`Error fetching photo: ${url}`, error);
    }
    return null;
  }

  async function loadMemberPhotos(data) {
    const photoPromises = data.flatMap(member => [
      member.member_photo_url ? fetchImage(member.member_photo_url) : null,
      member.household_photo_url ? fetchImage(member.household_photo_url) : null
    ]);
  
    const photoResults = await Promise.all(photoPromises);
  
    return data.map((member, index) => {
      const updatedMember = { ...member };
      if (member.member_photo_url) {
        updatedMember.member_photo = photoResults[index * 2];
      }
      if (member.household_photo_url) {
        updatedMember.household_photo = photoResults[index * 2 + 1];
      }
      return updatedMember;
    });
  }

  async function loadMemberPhoto(member) {
    const photoPromises = [
      member.member_photo_url ? fetchImage(member.member_photo_url) : null,
      member.household_photo_url ? fetchImage(member.household_photo_url) : null
    ];
  
    const [memberPhoto, householdPhoto] = await Promise.all(photoPromises);

    return {
      ...member,
      member_photo: memberPhoto,
      household_photo: householdPhoto
    };
  }

  async function fetchOverallNumbers() {
    try {
      const res = await fetch("/api/get_overall_numbers")
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function fetchMembers() {
    try {
      const res = await fetch("/api/list_members")
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function fetchIssuers() {
    try {
      const res = await fetch("/api/list_issuers")
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function fetchOrganizations() {
    try {
      const res = await fetch("/api/list_organizations")
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function fetchMembersWithCallings() {
    try {
      const res = await fetch("/api/list_members_with_callings")
      const data = await res.json();
      const membersWithPhotos = await loadMemberPhotos(data);
      return membersWithPhotos;
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function fetchNeedsCalling() {
    try {
      const res = await fetch("/api/list_needs_calling")
      const data = await res.json();
      const membersWithPhotos = await loadMemberPhotos(data);
      return membersWithPhotos;
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function fetchIneligibleForCalling() {
    try {
      const res = await fetch("/api/list_ineligible_for_calling")
      const data = await res.json();
      const membersWithPhotos = await loadMemberPhotos(data);
      return membersWithPhotos;
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function fetchPlaceholders() {
    try {
      const res = await fetch("/api/list_placeholders")
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error executing query for placeholders", error);
      return [];
    }
  }

  async function fetchRecommended() {
    try {
      const res = await fetch("/api/list_recommended")
      const data = await res.json();
      const membersWithPhotos = await loadMemberPhotos(data);
      return membersWithPhotos;
    } catch (error) {
      console.error("Error executing query for recommended", error);
      return [];
    }
  }

  async function fetchApproved() {
    try {
      const res = await fetch("/api/list_approved")
      const data = await res.json();
      const membersWithPhotos = await loadMemberPhotos(data);
      return membersWithPhotos;
    } catch (error) {
      console.error("Error executing query for approved", error);
      return [];
    }
  }

  async function fetchCalled() {
    try {
      const res = await fetch("/api/list_called")
      const data = await res.json();
      const membersWithPhotos = await loadMemberPhotos(data);
      return membersWithPhotos;
    } catch (error) {
      console.error("Error executing query for called", error);
      return [];
    }
  }

  async function fetchUnitDetails() {
    try {
      const res = await fetch("/api/get_unit_details")
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error fetching unit details", error);
      return {};
    }
  }

  const handleReleaseMember = async (callingId, isPendingRelease) => {
    try {
      await fetch("/api/release_from_calling", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ callingId: callingId, isPendingRelease: isPendingRelease }),
      });

      setMembersWithCallings((prevMembers) =>
        prevMembers.map((member) =>
          member.calling_id === callingId
            ? { ...member, is_pending_release: isPendingRelease }
            : member
        )
      );
    } catch (error) {
      console.error("Error releasing member from calling", error);
    }
  };

  const updateIneligibleForCalling = async (member, ineligibleForCalling) => {
    try {
      await fetch("/api/update_ineligible_for_calling", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: member.member_id, ineligibleForCalling: ineligibleForCalling }),
      })

      if (ineligibleForCalling) {
        setNeedsCalling(prev => prev.filter(item => item.member_id !== member.member_id));
        setIneligibleForCalling(prev => [...prev, { ...member, ineligible_for_calling: ineligibleForCalling }]);
      } else {
        setIneligibleForCalling(prev => prev.filter(item => item.member_id !== member.member_id));
        setNeedsCalling(prev => [...prev, { ...member, ineligible_for_calling: ineligibleForCalling }]);
      }
      const overallNumbers = await fetchOverallNumbers();
      setOverallNumbers(overallNumbers);
    } catch (error) {
      console.error("Error marking member ineligible for calling", error);
    }
  }

  const updateIsPrioritized = async (member, isPrioritized) => {
    try {
      await fetch("/api/update_is_prioritized", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: member.member_id, isPrioritized: isPrioritized }),
      })

      setNeedsCalling((prevMembers) =>
        prevMembers.map((item) =>
          item.member_id === member.member_id
            ? { ...item, is_prioritized: isPrioritized }
            : item
        )
      );
      setMembersList((prevMembers) =>
        prevMembers.map((item) => 
          item.value === member.member_id
            ? { ...item, is_prioritized: isPrioritized }
            : item
        )
      );
    } catch (error) {
      console.error("Error updating member's is_prioritized field", error);
    }
  }

  const handleUpdateSetApart = async (callingId, isSetApart) => {
    try {
      await fetch("/api/update_is_set_apart", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ callingId: callingId, isSetApart: isSetApart }),
      });

      setMembersWithCallings((prevMembers) =>
        prevMembers.map((member) =>
          member.calling_id === callingId
            ? { ...member, is_set_apart: isSetApart }
            : member
        )
      );
    } catch (error) {
      console.error("Error releasing member from calling", error);
    }
  };

  const handleSaveOrganization = async (orgId, title, issuerId) => {
    try {
      const res = await fetch("/api/update_organization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ title: title, issuerId: issuerId, orgId: orgId }),
      });
      const data = await res.json();
      setOrganizations(organizations.map(org => 
        org.id === orgId 
          ? { ...org, name: data.name, issuer_id: data.issuer_id } 
          : org
      ));
    } catch (error) {
      console.error("Error updating organization", error);
    }
  };

  async function initializePage() {
    try {
      setLoading(true);
      const sessionInfo = await getSessionInfo();
      setSessionInfo(sessionInfo);
      const overallNumbers = await fetchOverallNumbers();
      setOverallNumbers(overallNumbers);
      const unitDetails = await fetchUnitDetails();
      setUnitDetails(unitDetails)
      const membersWithCallings = await fetchMembersWithCallings();
      setMembersWithCallings(membersWithCallings);
      const organizations = await fetchOrganizations();
      setOrganizations(organizations);
      const placeholders = await fetchPlaceholders();
      setPlaceholders(placeholders);
      const recommended = await fetchRecommended();
      setRecommended(recommended);
      const approved = await fetchApproved();
      setApproved(approved);
      const called = await fetchCalled();
      setCalled(called);
      const issuersList = await fetchIssuers();
      setIssuersList(issuersList);
      setLoading(false);
    } catch (error) {
      console.error("Error initializing page", error);
      setLoading(false);
    }
  }

  async function lazyLoadDropdownData() {
    const membersList = await fetchMembers();
    setMembersList(membersList);

    const needsCalling = await fetchNeedsCalling();
    setNeedsCalling(needsCalling);

    const ineligibleForCalling = await fetchIneligibleForCalling();
    setIneligibleForCalling(ineligibleForCalling);
  }

  useEffect(() => {
    if (!loading && membersWithCallings.length > 0) {
      lazyLoadDropdownData();
    }
  }, [loading]);

  const EditOrganizationIcon = ({ onEdit }) => {
    return (
      <i
        className="bx bx-edit"
        style={{
          cursor: 'pointer',
          fontSize: '20px',
          marginLeft: '5px',
          verticalAlign: 'middle',
        }}
        onClick={onEdit}
      />
    );
  };

  const EditOrganizationModal = ({ organization, isOpen, onClose, onSave, issuersList }) => {
    const [title, setTitle] = useState(organization.name);
    const [selectedIssuer, setSelectedIssuer] = useState(issuersList.find(issuer => issuer.value === organization.issuer_id));
  
    const handleSave = () => {
      onSave(organization.id, title, selectedIssuer?.value);
      onClose();
    };
  
    if (!isOpen) return null;

    const inputStyles = {
      fontSize: "16px",
      width: "95%",
      border: "1px solid #b0b0b0",
      marginTop: "20px",
      padding: "10px",
      borderRadius: "4px", // optional for consistent rounding
    };

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        fontSize: "16px",
        width: "100%",
        textAlign: "left",
      }),
      option: (provided) => ({
        ...provided,
        fontSize: "16px",
        textAlign: "left",
      }),
      noOptionsMessage: (provided) => ({
        ...provided,
        fontSize: "16px",
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: "16px",
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: "16px",
      }),
      menu: (provided) => ({
        ...provided,
        border: "1px solid #b0b0b0",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        margin: "0",
        zIndex: 5000,
      }),
      menuList: (provided) => ({
        ...provided,
        paddingTop: "0",
        paddingBottom: "0",
      }),
      container: (provided) => ({
        ...provided,
        width: "100%",
      }),
    };
  
    return (
      <div className="modal-overlay">
        <div className="notes-modal-content" style={{ justifyContent: "space-between" }}>
          <div>
            <div style={{ fontSize: "18px" }}>
              <strong>Edit organization</strong>
            </div>
            <div>
              <input
                id="org-title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Organization name"
                style={inputStyles}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <Select
                id="org-issuer"
                value={selectedIssuer}
                onChange={setSelectedIssuer}
                options={issuersList}
                isClearable
                placeholder="Select admin"
                styles={selectStyles}
              />
            </div>
          </div>
          <div>
            <button className="cta" onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    );
  };

  const OrganizationSection = ({
    organization,
    isSubOrg = false,
    zoomedOut,
    issuersList = [],
    children,
  }) => {
    const issuer = issuersList.find(issuer => issuer.value === organization.issuer_id);
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <>
        <div className={isSubOrg ? "sub-organization-section" : "organization-section"}>
          {isSubOrg ? (
            <h3
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', position: 'relative' }}
            >
              {organization.name}
              {!zoomedOut && (
                <span style={{ position: 'absolute', right: '25px' }}>
                  <EditOrganizationIcon organization={organization} onEdit={() => setIsOpen(true)} />
                </span>
              )}
            </h3>
          ) : (
            <h2
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', position: 'relative' }}
            >
              {organization.name}
              {issuer && (<span style={{ fontSize: "20px" }}>({issuer.label})</span>)}
              {!zoomedOut && (
                <span style={{ position: 'absolute', right: '25px' }}>
                  <EditOrganizationIcon organization={organization} onEdit={() => setIsOpen(true)} />
                </span>
              )}
            </h2>
          )}
          <div className={isSubOrg ? "sub-organization-content" : "organization-content"}>
            {children}
          </div>
        </div>
        {isOpen && (
          <EditOrganizationModal
            organization={organization}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onSave={handleSaveOrganization}
            issuersList={issuersList}
          />
        )}
      </>
    );
  };

  const renderOrganizations = () => {
    const parentOrgs = organizations.filter((org) => org.parent_id === null);

    return parentOrgs.map((parentOrg) => (
      <OrganizationSection 
        key={parentOrg.id} 
        organization={parentOrg}
        zoomedOut={zoomedOut}
        issuersList={issuersList}
      >
        <div className="organization-content">
          <div className="members">{renderCallingCards(parentOrg.id, parentOrg.allow_new_callings && sessionInfo.canRecommendCallings, parentOrg.sisters_only, parentOrg.brothers_only)}</div>
        </div>
        <div className="sub-organizations-container">
          {organizations
            .filter((org) => org.parent_id === parentOrg.id)
            .map((subOrg) => (
              <OrganizationSection
                key={subOrg.id}
                organization={subOrg}
                isSubOrg={true}
                zoomedOut={zoomedOut}
              >
                <div className="members">{renderCallingCards(subOrg.id, subOrg.allow_new_callings && sessionInfo.canRecommendCallings, subOrg.sisters_only, subOrg.brothers_only)}</div>
              </OrganizationSection>
            ))}
        </div>
      </OrganizationSection>
    ));
  };

  async function handleAddPendingCalling(callingName, orgId) {
    try {
      const res = await fetch("/api/create_pending_calling", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: callingName, organization_id: orgId }),
      });
      const data = await res.json();
      setPlaceholders((prevPlaceholders) => [...prevPlaceholders, data]);
    } catch (error) {
      console.error("Error adding new calling", error);
    }
  }

  async function handleAddRecommendation(calling) {
    try {
      const res = await fetch("/api/update_pending_calling", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...calling }),
      });
      let data = await res.json();
      data = await loadMemberPhoto(data);
      data = { ...data, reviewer_ids: [] };
      setPlaceholders((prevPlaceholders) => prevPlaceholders.filter(item => item.id !== data.id));
      setRecommended((prevRecommended) => [...prevRecommended, data]);
    } catch (error) {
      console.error("Error adding recommendation", error);
    }
  }

  async function handleAddReview(callingId) {
    try {
      const calling = recommended.find(row => row.id === callingId);
      calling.reviewer_ids.push(sessionInfo.userId);
      if (calling.reviewer_ids.length == calling.reviewer_count) {
        setApproved(prevApproved => [...prevApproved, { ...calling, is_approved: true }]);
        setRecommended(prevRecommended => prevRecommended.filter(row => row.id !== calling.id));
        await fetch("/api/update_pending_calling", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...calling, is_approved: true }),
        });
      } else {
        setRecommended(prevRecommended => prevRecommended.map(row => row.id === calling.id ? calling : row));
      }

      await fetch("/api/create_review", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ callingId: callingId }),
      });
    } catch (error) {
      console.error("Error reviewing calling", error);
    }
  }

  async function deletePendingCalling(pendingCallingId) {
    try {
      await fetch("/api/delete_pending_calling", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pendingCallingId: pendingCallingId })
      });
      setPlaceholders(placeholders.filter(item => item.id !== pendingCallingId));
      setRecommended(recommended.filter(item => item.id !== pendingCallingId));
      setApproved(approved.filter(item => item.id !== pendingCallingId));
      setCalled(called.filter(item => item.id !== pendingCallingId));
    } catch (error) {
      console.error("Error deleting note", error);
    }
  };

  async function handleUpdateApproved(calling) {
    try {
      const res = await fetch("/api/update_pending_calling", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...calling }),
      });
      let data = await res.json();
      data = await loadMemberPhoto(data);
      if (data.is_accepted) {
        setApproved((prevApproved) => prevApproved.filter(item => item.id !== data.id));
        setCalled((prevCalled) => [...prevCalled, data]);
      }
    } catch (error) {
      console.error("Error updating pending calling", error);
    }
  }

  async function handleUpdateCalled(calling) {
    try{
      await fetch("/api/update_pending_calling", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...calling }),
      });
      setCalled((prevCalled) =>
        prevCalled.map((row) =>
          row.id === calling.id
            ? { ...row, sustained_date: calling.sustained_date, is_set_apart: calling.is_set_apart}
            : row
        )
      );
    } catch(error) {
      console.error("Error updating pending calling", error);
    }
  }

  const renderCallingCards = (organizationId, showAddCallingCard, sistersOnly, brothersOnly) => {
    function getPositionPriority(position) {
      if (position == "Bishop") return 1;
      if (/chair|president|lead/i.test(position)) return 2;
      if (/first counselor|1st counselor/i.test(position)) return 3;
      if (/second counselor|2nd counselor/i.test(position)) return 4;
      if (/assistant/i.test(position)) return 6; // Deprioritize positions with "assistant"
      return 5; // Default for any other positions
    }

    const filteredMembers = membersWithCallings.filter((row) => row.organization_id === organizationId).sort((a, b) => getPositionPriority(a.position) - getPositionPriority(b.position));
    const filteredCalled = called.filter((row) => row.organization_id === organizationId).sort((a, b) => getPositionPriority(a.name) - getPositionPriority(b.name));
    const filteredApproved = approved.filter((row) => row.organization_id === organizationId).sort((a, b) => getPositionPriority(a.name) - getPositionPriority(b.name));
    const filteredRecommended = recommended.filter((row) => row.organization_id === organizationId).sort((a, b) => getPositionPriority(a.name) - getPositionPriority(b.name));
    const filteredPlaceholders = placeholders.filter((row) => row.organization_id === organizationId).sort((a, b) => getPositionPriority(a.name) - getPositionPriority(b.name));
    const filteredMembersList = membersList.filter(member => {
      if (sistersOnly) {
        return member.gender === 'F';
      } 
      if (brothersOnly) {
        return member.gender === 'M';
      }
      return true;
    });
  
    const cards = [
      ...filteredMembers.map((row) => (
        <MemberWithCalling
          key={row.calling_id}
          member={row}
          zoomedOut={zoomedOut}
          unitDetails={unitDetails}
          sessionInfo={sessionInfo}
          onRelease={handleReleaseMember}
          onUpdateSetApart={handleUpdateSetApart}
        />
      )),
      ...filteredCalled.map((row) => (
        <CalledCard
          key={row.id}
          calling={row}
          zoomedOut={zoomedOut}
          onUpdate={handleUpdateCalled}
        />
      )),
      ...filteredApproved.map((row) => (
        <ApprovedCard
          key={row.id}
          calling={row}
          zoomedOut={zoomedOut}
          sessionInfo={sessionInfo}
          onUpdate={handleUpdateApproved}
          deletePendingCalling={deletePendingCalling}
          issuersList={issuersList}
        />
      )),
      ...filteredRecommended.map((row) => (
        <RecommendedCard
          key={row.id}
          calling={row}
          zoomedOut={zoomedOut}
          sessionInfo={sessionInfo}
          onReview={handleAddReview}
          deletePendingCalling={deletePendingCalling}
        />
      )),
      ...filteredPlaceholders.map((row) => (
        <PlaceholderCard
          key={row.id}
          calling={row}
          zoomedOut={zoomedOut}
          sessionInfo={sessionInfo}
          onRecommend={handleAddRecommendation}
          deletePendingCalling={deletePendingCalling}
          membersList={filteredMembersList}
        />
      ))
    ];
  
    if (!zoomedOut && showAddCallingCard) {
      cards.push(
        <AddCallingCard
          onAdd={handleAddPendingCalling}
          organizationId={organizationId}
        />
      );
    }
  
    return cards;
  };

  const LoadingSpinner = () => {
    return (
      <div className="loading-spinner-container">
        <i className="bx bx-loader-alt loading-spinner"></i>
      </div>
    );
  };

  const Notification = ({ message, onClose }) => {
    return (
      <div className="notification">
        <span>{message}</span>
        <button onClick={onClose} className="close-btn">
          &times;
        </button>
      </div>
    );
  };

  const [notification, setNotification] = useState(null);

  const updateNotification = (message) => {
    setNotification(message);
  };

  // useEffect(() => {
  //   const pendingReviewsCount = Object.values(pendingCallingCards).flat().filter(
  //     calling => !calling.reviews.some(review => review.reviewer_id === sessionInfo.memberId) && calling.recommender.value !== sessionInfo.memberId
  //   ).length;
  
  //   if (pendingReviewsCount > 0) {
  //     updateNotification(`${pendingReviewsCount} calling${pendingReviewsCount > 1 ? 's' : ''} need${pendingReviewsCount === 1 ? 's' : ''} your review.`);
  //   } else {
  //     setNotification(null);
  //   }
  // }, [pendingCallingCards, sessionInfo.memberId]);

  return (
    <div className={`App ${zoomedOut ? "zoomed-out" : ""}`}>
      {sessionInfo.title ? (
        <Header
          title={sessionInfo.title}
          activeTab={activeTab}
          overallNumbers={overallNumbers}
        />
      ) : (
          <header className="App-header">
            <span className="App-logo">LCR Plus</span>
            <span className="error-message">{errorMessage}</span>
            {/* <span className="tagline">Automating everything about your calling minus the literal salvation of souls.</span>
            <div className="comparison">
              <span className="animated-strikethrough">Excel, spreadsheets, Google Sheets.</span>
              <span className="animated-highlight">Simple. efficient. LCR Plus.</span>
              <span>The products (services) offered by LCR Plus are neither made, provided, approved, nor endorsed by Intellectual Reserve, Inc. or The Church of Jesus Christ of Latter-day Saints. Any content or opinions expressed, implied, or included in or with the goods (services) offered by LCR Plus are solely those of LCR Plus and not those of Intellectual Reserve, Inc. or The Church of Jesus Christ of Latter-day Saints.</span>
            </div> */}
          </header>
      )}
      {activeTab === "membersWithCallings" && unitDetails && (
        <MembersWithCallingsLegend
          zoomedOut={zoomedOut}
          toggleView={toggleView}
          activeTab={activeTab}
          handleTabSelection={handleTabSelection}
          unitDetails={unitDetails}
        />
      )}
      {activeTab === "needsCalling" && (
        <NeedsCallingLegend
          zoomedOut={zoomedOut}
          toggleView={toggleView}
          activeTab={activeTab}
          handleTabSelection={handleTabSelection}
        />
      )}
      {activeTab === "ineligibleForCalling" && (
        <IneligibleForCallingLegend
          zoomedOut={zoomedOut}
          toggleView={toggleView}
          activeTab={activeTab}
          handleTabSelection={handleTabSelection}
        />
      )}
      {activeTab === "ministering" && (
        <MinisteringLegend
          zoomedOut={zoomedOut}
          toggleView={toggleView}
          activeTab={activeTab}
          handleTabSelection={handleTabSelection}
        />
      )}
      {loading && overallNumbers && <LoadingSpinner />}
      {!loading && membersWithCallings.length > 0 && (
        <body className="App-body">
          {activeTab === "membersWithCallings" && (
            <div className="organizations-container">
              {renderOrganizations()}
            </div>
          )}
          {activeTab === "needsCalling" && (
            <div className="members">
              {needsCalling.map((member, index) => (
                <MemberWithoutCalling 
                  key={index}
                  member={member}
                  updateIsPrioritized={updateIsPrioritized}
                  updateIneligibleForCalling={updateIneligibleForCalling}
                  zoomedOut={zoomedOut} 
                  sessionInfo={sessionInfo}
                />
              ))}
            </div>
          )}
          {activeTab === "ineligibleForCalling" && (
            ineligibleForCalling.length > 0 ?
              (<div className="members">
                {ineligibleForCalling.map((member, index) => (
                  <IneligibleForCalling
                    key={index}
                    member={member}
                    updateIneligibleForCalling={updateIneligibleForCalling}
                    zoomedOut={zoomedOut}
                    sessionInfo={sessionInfo}
                  />
                ))}
              </div>
            ) : (
              <div className="empty-state">
                No members are marked as ineligible for a calling.
              </div>
            )
          )}
          {activeTab === "ministering" && (
            <Ministering />
          )}
          {activeTab === "recordInLcr" && (
            <div className="members">
              {/* {callingsToExtend.map((member, index) => (
                <CallingToExtend key={index} member={member} />
              ))} */}
            </div>
          )}
        </body>
      )}
    </div>
  );
};
