import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { NotesModal } from "./NotesModal";
import { formatName } from "./utils";
import React, { useState } from "react";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";

export const MemberWithCalling = ({ member, zoomedOut, unitDetails, sessionInfo, onRelease, onUpdateSetApart }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [memberNotes, setMemberNotes] = useState([]);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isPendingRelease, setIsPendingRelease] = useState(member.is_pending_release);

  const handleNameClick = async () => {
    const notes = await fetchMemberNotes();
    setMemberNotes(notes);
    setIsNotesModalOpen(true);
  };

  const handleAddNote = async (note) => {
    const newNote = await addNoteToDatabase(member.id, note);
    setMemberNotes([newNote, ...memberNotes]);
  };

  const fetchMemberNotes = async () => {
    try {
      // make this a GET req instead
      const res = await fetch("/api/list_notes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          memberId: member.id,
        }),
      });
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error fetching member notes", error);
      return [];
    }
  };

  const addNoteToDatabase = async (note) => {
    try {
      const res = await fetch("/api/create_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          memberId: member.id,
          note: note,
        }),
      });
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error adding note", error);
      return null;
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      const res = await fetch("/api/delete_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ noteId: noteId })
      });
      const data = await res.json();
      if (data) {
        setMemberNotes(memberNotes.filter(note => note.id !== noteId));
      }
    } catch (error) {
      console.error("Error deleting note", error);
    }
  };

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  let isDefaultPicture = false;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  } else {
    currentPhotoSrc = "/no_profile_picture.jpeg";
    isDefaultPicture = true;
  }

  const name = formatName(member.member_name)

  const sustainedDate = new Date(member.sustained_date);

  // Determine border color
  const monthsSinceSustained = member.months_since_sustained;
  let borderColor = "#b0b0b0"; // Default border color
  if (isPendingRelease) {
    borderColor = "#ea4335"
  } else if (monthsSinceSustained >= unitDetails.longer_tenure_months) {
    borderColor = "#4285F4"; // custom, but close to cornflower blue
  } else if (monthsSinceSustained >= unitDetails.shorter_tenure_months) {
    borderColor = "#FFBBFF"; // light magenta 1
  }

  const handleReleaseConfirm = () => {
    setIsPendingRelease(!isPendingRelease)
    onRelease(member.calling_id, !isPendingRelease);
  };

  if (zoomedOut) {
    return (
      <div
        className="member-card"
        style={{
          border: `5px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "125px",
            width: "125px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <img
            src={currentPhotoSrc}
            alt={name}
            className="member-photo"
            style={isDefaultPicture ? { height: "75px", width: "75px" } : {}}
          />
        </div>
        <div
          style={{
            fontSize: "15px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "150px",
            /*cursor: "pointer"*/
          }}
          /*onClick={() => handleNameClick()}*/
        >
          <strong>{name}</strong>
        </div>
        <div
          style={{
            fontSize: "13px",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            fontStyle: "italic",
            // whiteSpace: "nowrap",
            // overflow: "hidden",
            // textOverflow: "ellipsis",
            width: "150px",
          }}
        >
          {member.position}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `5px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-5px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded" style={{ fontSize: "24px" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              {!isPendingRelease && (<DropdownMenuRadioItem
                className='dropdown-item'
                onClick={handleReleaseConfirm}
                style={{ fontSize: "18px" }}
              >
                Release from calling
              </DropdownMenuRadioItem>)}
              {isPendingRelease && (<DropdownMenuRadioItem
                className='dropdown-item'
                onClick={handleReleaseConfirm}
                style={{ fontSize: "18px" }}
              >
                Clear released status
              </DropdownMenuRadioItem>)}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div
          style={{
            position: "relative",
            width: "240px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                right: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <img
              src={currentPhotoSrc}
              alt={name}
              className="member-photo"
              style={isDefaultPicture ? { height: "100px", width: "100px" } : {}}
            />
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                left: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            fontSize: "18px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            /*cursor: "pointer",*/
          }}
          /*onClick={() => handleNameClick()}*/
        >
          <strong>{name}</strong>
        </div>
        <div
          style={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            fontStyle: "italic",
            marginBottom: "8px",
          }}
        >
          {member.position}
        </div>
        <div style={{ fontSize: "15px", display: "flex", gap: "8px" }}>
          <div>
            <div>
              <strong>Sustained:</strong>
            </div>
            <div>{format(sustainedDate, "MM/dd/yy")}</div>
          </div>
          <div
            style={{
              borderRight: "1px solid #261232",
              height: "80%",
              margin: "0 10px",
            }}
          ></div>
          <div>
            <div>
              <strong>Set apart:</strong>
            </div>
            <div>
              <i
                className={member.is_set_apart ? "bx bx-checkbox-checked" : "bx bx-checkbox"} 
                style={{ fontSize: "23px", cursor: "pointer" }} 
                onClick={() => onUpdateSetApart(member.calling_id, !member.is_set_apart)}
              ></i>
            </div>
          </div>
        </div>
      </div>
      <NotesModal
        isOpen={isNotesModalOpen}
        onClose={() => setIsNotesModalOpen(false)}
        memberId={member.id}
        memberName={member.member_name}
        notes={memberNotes}
        onAddNote={handleAddNote}
        onDeleteNote={handleDeleteNote}
        sessionInfo={sessionInfo}
      />
    </>
  );
};