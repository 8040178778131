import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { ViewToggleIcon } from "./ViewToggleIcon";

// https://simplicable.com/colors/earth-tone
export const IneligibleForCallingLegend = ({ zoomedOut, toggleView, activeTab, handleTabSelection }) => {
  return (
    <div className="legend">
      <div className="legend-controls">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}>
              <i className="bx bx-menu" style={{ fontSize: "24px" }}></i>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
          >
            <DropdownMenuRadioGroup value={activeTab} onValueChange={handleTabSelection}>
              <DropdownMenuRadioItem
                value="membersWithCallings"
                className={`dropdown-item ${activeTab === "membersWithCallings" ? "selected" : ""}`}
              >
                Members with callings
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem
                value="needsCalling"
                className={`dropdown-item ${activeTab === "needsCalling" ? "selected" : ""}`}
              >
                Needs a calling
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem
                value="ineligibleForCalling"
                className={`dropdown-item ${activeTab === "ineligibleForCalling" ? "selected" : ""}`}
              >
                Ineligible for calling
              </DropdownMenuRadioItem>
              {/* <DropdownMenuRadioItem
                value="ministering"
                className={`dropdown-item ${activeTab === "ministering" ? "selected" : ""}`}
              >
                Ministering
              </DropdownMenuRadioItem> */}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="legend-icons">
          <ViewToggleIcon zoomedOut={zoomedOut} toggleView={toggleView} />
        </div>
      </div>
    </div>
  );
};