import Select from "react-select";
import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";

export const PlaceholderCard = ({
  calling,
  zoomedOut,
  sessionInfo,
  onRecommend,
  deletePendingCalling,
  membersList,
}) => {
  const [recommended, setRecommended] = useState(null);

  useEffect(() => {
    if (recommended) {
      onRecommend({ ...calling, recommended_id: recommended.value });
    }
  }, [recommended]);

  const priorityOptions = membersList
  .filter((member) => member.is_prioritized)
  .sort((a, b) => a.label.localeCompare(b.label));

  const otherOptions = membersList
    .filter((member) => !member.is_prioritized)
    .sort((a, b) => a.label.localeCompare(b.label));

  // Separate and sort members into two groups: prioritized and non-prioritized
  const groupedOptions = priorityOptions.length ? [
    {
      label: "Priority",
      options: priorityOptions
    },
    {
      label: "Other",
      options: otherOptions
    },
  ] : membersList;
  console.log(`priorityOptions: ${JSON.stringify(priorityOptions)}`)

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "100%",
      textAlign: "left",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "16px",
      textAlign: "left",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #b0b0b0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  if (zoomedOut) {
    return (
      <div
        className="member-card"
        style={{
          border: "5px solid #f6d76e",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ fontSize: "24px", fontStyle: "italic" }}>
          {calling.name}
        </div>
      </div>
    );
  }

  return (
    <div
      className="member-card"
      style={{
        border: "5px solid #f6d76e",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "0px",
            right: "-5px",
            zIndex: 1000,
          }}
          asChild
        >
          <i
            className="bx bx-dots-vertical-rounded"
            style={{ fontSize: "24px" }}
          ></i>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="dropdown-content"
          align="end"
          side="bottom"
          style={{ zIndex: 1000 }}
        >
          <DropdownMenuRadioGroup>
            <DropdownMenuRadioItem
              className="dropdown-item"
              onClick={() => deletePendingCalling(calling.id)}
              style={{ fontSize: "18px" }}
            >
              Delete placeholder
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <div
        style={{
          fontSize: "24px",
          width: "240px",
          fontStyle: "italic",
          marginBottom: "8px",
        }}
      >
        {calling.name}
      </div>
      {sessionInfo.canRecommendCallings && (
        <Select
          value={recommended}
          onChange={(option) => setRecommended(option)}
          options={groupedOptions}
          placeholder="Recommend member"
          styles={selectStyles}
        />
      )}
    </div>
  );
};