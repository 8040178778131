import React from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling

export const Header = ({
  title,
  activeTab,
  overallNumbers,
}) => {
  return (
    <div className="header">
      <h1 className="welcome">Welcome, {title}</h1>
      {overallNumbers && (
        <div className="overall-container">
          {activeTab === "membersWithCallings" && (
            <div className="overall-box">
              <div className="overall-label">Members with callings</div>
              <div className="overall-number">{overallNumbers["members_with_callings_count"]}</div>
            </div>
          )}
          {activeTab === "needsCalling" && (
            <div className="overall-box">
              <div className="overall-label">Needs a calling</div>
              <div className="overall-number">{overallNumbers["needs_calling_count"]}</div>
            </div>
          )}
          {activeTab === "ineligibleForCalling" && (
            <div className="overall-box">
              <div className="overall-label">Ineligible for calling</div>
              <div className="overall-number">{overallNumbers["ineligible_for_calling_count"]}</div>
            </div>
          )}
          {activeTab === "ineligibleForCalling" && (
            <div className="overall-box">
              <div className="overall-label">
                Total members
              </div>
              <div className="overall-number">{overallNumbers["total_members_count"]}</div>
            </div>
          )}
          {(activeTab === "membersWithCallings" || activeTab === "needsCalling") && (
            <div className="overall-box">
              <div className="overall-label">
                Total members
                <Tippy content='Excludes members marked "ineligible for calling"' theme="lcrplus">
                  <i className="bx bx-info-circle" style={{ marginLeft: '5px' }}></i>
                </Tippy>
              </div>
              <div className="overall-number">{overallNumbers["eligible_members_count"]}</div>
            </div>
          )}
          {activeTab === "membersWithCallings" && (
            <div className="overall-box">
              <div className="overall-label">Percent with callings</div>
              <div className="overall-number">{(overallNumbers["members_with_callings_count"] / overallNumbers["eligible_members_count"] * 100).toFixed()}%</div>
            </div>
          )}
          {activeTab === "needsCalling" && (
            <div className="overall-box">
              <div className="overall-label">Percent without callings</div>
              <div className="overall-number">{(overallNumbers["needs_calling_count"] / overallNumbers["eligible_members_count"] * 100).toFixed()}%</div>
            </div>
          )}
          {activeTab === "ineligibleForCalling" && (
            <div className="overall-box">
              <div className="overall-label">Percent ineligible</div>
              <div className="overall-number">{(overallNumbers["ineligible_for_calling_count"] / overallNumbers["total_members_count"] * 100).toFixed()}%</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}