import { useEffect, useState} from "react";
import { formatName } from "./utils";
import Select from "react-select";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";

// note to self: need to show who is to issue the call above the button for "calling accepted"
export const ApprovedCard = ({ calling, zoomedOut, onUpdate, deletePendingCalling, sessionInfo, issuersList }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [issuer, setIssuer] = useState(calling.issuer_id ? {value: calling.issuer_id, label: calling.issuer_title} : null);

  const updateIssuer = (option) => {
    setIssuer(option);
    onUpdate({ ...calling, issuer_id: option.value })
  };

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = calling.member_photo;
  let householdPhotoSrc = calling.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  let isDefaultPicture = false;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  } else {
    currentPhotoSrc = "/no_profile_picture.jpeg";
    isDefaultPicture = true;
  }

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "100%",
      textAlign: "left",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "16px",
      textAlign: "left",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #b0b0b0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  if (zoomedOut) {
    return (
      <div
        className="member-card"
        style={{
          border: `5px solid #f6d76e`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "125px",
            width: "125px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <img
            src={currentPhotoSrc}
            alt={formatName(calling.recommended_name)}
            className="member-photo"
            style={isDefaultPicture ? { height: "75px", width: "75px" } : {}}
          />
        </div>
        <div style={{ fontSize: "15px" }}>
          <strong>{formatName(calling.recommended_name)}</strong>
        </div>
        <div style={{ fontSize: "13px", fontStyle: "italic" }}>
          {calling.name}
        </div>
      </div>
    );
  }

  return (
    <div
      className="member-card"
      style={{
        border: `5px solid #f6d76e`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <DropdownMenu>
        <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-5px", zIndex: 1000}} asChild>
          <i className="bx bx-dots-vertical-rounded" style={{ fontSize: "24px" }}></i>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="dropdown-content"
          align="end"
          side="bottom"      
          style={{zIndex: 1000}}
        >
          <DropdownMenuRadioGroup>
            <DropdownMenuRadioItem
              className='dropdown-item'
              onClick={() => deletePendingCalling(calling.id)}
              style={{ fontSize: "18px" }}
            >
              Delete recommendation
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <div
        style={{
          position: "relative",
          width: "240px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
          <button
            className="photo-toggle bx bx-chevron-right"
            onClick={togglePhoto}
            style={{
              position: "absolute",
              right: "-15px",
              top: "50%",
              transform: "translateY(-50%)",
              border: "none",
              background: "none",
              fontSize: "24px",
              cursor: "pointer",
            }}
          />
        )}
        <div
          style={{
            height: "210px",
            width: "210px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <img
            src={currentPhotoSrc}
            alt={formatName(calling.recommended_name)}
            className="member-photo"
            style={isDefaultPicture ? { height: "50px", width: "50px" } : {}}
          />
        </div>
        {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
          <button
            className="photo-toggle bx bx-chevron-left"
            onClick={togglePhoto}
            style={{
              position: "absolute",
              left: "-15px",
              top: "50%",
              transform: "translateY(-50%)",
              border: "none",
              background: "none",
              fontSize: "24px",
              cursor: "pointer",
            }}
          />
        )}
      </div>
      <div
        style={{
          fontSize: "18px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "240px",
        }}
      >
        <strong>{formatName(calling.recommended_name)}</strong>
      </div>
      <div
        style={{
          fontSize: "16px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "240px",
          fontStyle: "italic",
          marginBottom: "8px",
        }}
      >
        {calling.name}
      </div>
      {sessionInfo.canIssueCallings && !issuer && (
        <Select
          value={issuer}
          onChange={updateIssuer}
          options={issuersList}
          placeholder="To issue call"
          styles={selectStyles}
        />
      )}
      {sessionInfo.canIssueCallings && issuer && (
        <div style={{ fontSize: "15px", display: "flex", gap: "8px" }}>
          <div>
            <div>
              <strong>To issue call:</strong>
            </div>
            <div>{issuer.label}</div>
          </div>
          <div
            style={{
              borderRight: "1px solid #261232",
              height: "80%",
              margin: "0 10px",
            }}
          ></div>
          <button onClick={() => onUpdate({ ...calling, is_accepted: true })} className="cta">Accepted</button>
        </div>
      )}
    </div>
  );
};