import { useRef, useState, useEffect } from "react";
import { formatName } from "./utils";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";

export const CalledCard = ({ calling, zoomedOut, onUpdate }) => {
  const calendarRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [sustainedDate, setSustainedDate] = useState(calling.sustained_date);
  const [isSetApart, setIsSetApart] = useState(calling.is_set_apart);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setEditing(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const updateSustainedDate = (newDate) => {
    setSustainedDate(newDate);
    setEditing(false);
    onUpdate({ ...calling, sustained_date: newDate });
  };

  const updateIsSetApart = () => {
    setIsSetApart(!isSetApart);
    onUpdate({ ...calling, is_set_apart: !isSetApart });
  };

  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  let memberPhotoSrc = calling.member_photo;
  let householdPhotoSrc = calling.household_photo;

  let currentPhotoSrc;
  let isDefaultPicture = false;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  } else {
    currentPhotoSrc = "/no_profile_picture.jpeg";
    isDefaultPicture = true;
  }

  if (zoomedOut) {
    return (
      <div
        className="member-card"
        style={{
          border: `5px solid #f6d76e`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "125px",
            width: "125px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <img
            src={currentPhotoSrc}
            alt={formatName(calling.recommended_name)}
            className="member-photo"
            style={isDefaultPicture ? { height: "75px", width: "75px" } : {}}
          />
        </div>
        <div style={{ fontSize: "15px" }}>
          <strong>{formatName(calling.recommended_name)}</strong>
        </div>
        <div style={{ fontSize: "13px", fontStyle: "italic" }}>
          {calling.name}
        </div>
      </div>
    );
  }

  return (
    <div
      className="member-card"
      style={{
        border: `5px solid #f6d76e`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Photo section */}
      <div
        style={{
          position: "relative",
          width: "240px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
          <button
            className="photo-toggle bx bx-chevron-right"
            onClick={togglePhoto}
            style={{
              position: "absolute",
              right: "-15px",
              top: "50%",
              transform: "translateY(-50%)",
              border: "none",
              background: "none",
              fontSize: "24px",
              cursor: "pointer",
            }}
          />
        )}
        <div
          style={{
            height: "210px",
            width: "210px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <img
            src={currentPhotoSrc}
            alt={formatName(calling.recommended_name)}
            className="member-photo"
            style={isDefaultPicture ? { height: "50px", width: "50px" } : {}}
          />
        </div>
        {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
          <button
            className="photo-toggle bx bx-chevron-left"
            onClick={togglePhoto}
            style={{
              position: "absolute",
              left: "-15px",
              top: "50%",
              transform: "translateY(-50%)",
              border: "none",
              background: "none",
              fontSize: "24px",
              cursor: "pointer",
            }}
          />
        )}
      </div>
      
      {/* Name and calling section */}
      <div
        style={{
          fontSize: "18px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "240px",
        }}
      >
        <strong>{formatName(calling.recommended_name)}</strong>
      </div>
      <div
        style={{
          fontSize: "16px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "240px",
          fontStyle: "italic",
          marginBottom: "8px",
        }}
      >
        {calling.name}
      </div>
      
      {/* Sustained and Set Apart section */}
      <div style={{ fontSize: "15px", display: "flex", gap: "8px" }}>          
        <div style={{ fontSize: "15px", position: "relative" }}>
          <div>
            <strong>Sustained:</strong>
          </div>
          <div>
            <span
              onClick={() => setEditing(true)}
              style={{
                cursor: "pointer",
                textDecoration: !sustainedDate ? "underline" : "none",
              }}
            >
              {sustainedDate ? format(sustainedDate, "MM/dd/yy") : <i className="bx bx-calendar-edit"></i>}
            </span>
            {editing && (
              <div
                ref={calendarRef}
                style={{ position: "absolute", zIndex: 1000 }}
              >
                <DayPicker
                  mode="single"
                  selected={sustainedDate}
                  onSelect={updateSustainedDate}
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #b0b0b0",
                    borderRadius: "4px",
                    padding: "10px",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            borderRight: "1px solid #261232",
            height: "80%",
            margin: "0 10px",
          }}
        ></div>

        <div>
          <div>
            <strong>Set apart:</strong>
          </div>
          <div>
            <i 
              className={isSetApart ? "bx bx-checkbox-checked" : "bx bx-checkbox"} 
              style={{ fontSize: "23px", cursor: "pointer" }} 
              onClick={updateIsSetApart}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};