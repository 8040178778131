import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { ViewToggleIcon } from "./ViewToggleIcon";

// https://simplicable.com/colors/earth-tone
export const MembersWithCallingsLegend = ({ zoomedOut, toggleView, activeTab, handleTabSelection, unitDetails }) => {
  return (
    <div className="legend">
      <div className="legend-controls">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}>
              <i className="bx bx-menu" style={{ fontSize: "24px" }}></i>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
          >
            <DropdownMenuRadioGroup value={activeTab} onValueChange={handleTabSelection}>
              <DropdownMenuRadioItem
                value="membersWithCallings"
                className={`dropdown-item ${activeTab === "membersWithCallings" ? "selected" : ""}`}
              >
                Members with callings
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem
                value="needsCalling"
                className={`dropdown-item ${activeTab === "needsCalling" ? "selected" : ""}`}
              >
                Needs a calling
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem
                value="ineligibleForCalling"
                className={`dropdown-item ${activeTab === "ineligibleForCalling" ? "selected" : ""}`}
              >
                Ineligible for calling
              </DropdownMenuRadioItem>
              {/* <DropdownMenuRadioItem
                value="ministering"
                className={`dropdown-item ${activeTab === "ministering" ? "selected" : ""}`}
              >
                Ministering
              </DropdownMenuRadioItem> */}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              color: "#f6d76e", // Swedish yellow
              backgroundColor: "#f6d76e", // Swedish yellow
              marginRight: "4px",
              fontSize: "24px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <strong>Recommended</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              color: "#f0944d", // faded orange
              backgroundColor: "#f0944d", // faded orange
              margin: "4px",
              fontSize: "24px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <strong>Approved</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              color: "#836539", // dirt brown
              backgroundColor: "#836539", // dirt brown
              marginRight: "4px",
              fontSize: "24px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <strong>Called</strong>
        </div> */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              color: "white",
              marginRight: "4px",
              fontSize: "16px",
              borderRadius: "6px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              border: "4px solid #f6d76e",
            }}
          />
          <strong>Pending calling</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              color: "white",
              marginRight: "4px",
              fontSize: "16px",
              borderRadius: "6px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              border: "4px solid #ffbbff",
            }}
          />
          <strong>{unitDetails.shorter_tenure_label}</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              color: "white",
              marginRight: "4px",
              fontSize: "16px",
              borderRadius: "6px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              border: "4px solid #278ad8",
            }}
          />
          <strong>{unitDetails.longer_tenure_label}</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              color: "white",
              marginRight: "4px",
              fontSize: "16px",
              borderRadius: "6px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              border: "4px solid #ea4335",
            }}
          />
          <strong>Pending release</strong>
        </div>
        <div className="legend-icons">
          <ViewToggleIcon zoomedOut={zoomedOut} toggleView={toggleView} />
        </div>
      </div>
    </div>
  );
};