import React, { useState } from "react";

export const AddCallingCard = ({
  onAdd,
  organizationId,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [callingName, setCallingName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(callingName, organizationId);
    setIsAdding(false);
  };

  if (!isAdding) {
    return (
      <div
        className="add-card" 
        style={{ cursor: "pointer" }}
        onClick={() => setIsAdding(true)}>
          <div
            className="bx bx-plus-circle"
            style={{ fontSize: "24px" }}
          ></div>
      </div>
    );
  }

  const inputStyles = {
    fontSize: "16px",
    width: "85%",
    border: "1px solid #b0b0b0",
    marginTop: "10px",
    padding: "10px",
    borderRadius: "4px", // optional for consistent rounding
  };

  return (
    <div className="add-card">
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}
      >
        <div>
          <input
            style={inputStyles}
            type="text"
            value={callingName}
            onChange={(e) => setCallingName(e.target.value)}
            placeholder="Calling name"
            required
          />
        </div>
        <div>
          <button
            className="cta"
            disabled={callingName.length === 0}
            style={{
              cursor: callingName.length === 0 ? "default" : "pointer",
            }}
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};